<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info
          :labelCol="8"
          :valueCol="14"
          title="商品名称"
          :value="goods.title"
        />
        <e-info
          :labelCol="8"
          :valueCol="14"
          title="商品单位"
          :value="goods.unit"
        />
        <e-input-number
          :labelCol="8"
          :valueCol="14"
          title="出库数量"
          v-model:value="count"
        />
        <e-select
          title="出库用户"
          :labelCol="8"
          :valueCol="14"
          v-model:value="userId"
          :options="userList"
        />
        <e-textarea
          :labelCol="8"
          :valueCol="14"
          title="备注"
          v-model:value="remark"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { GoodsClass } from '@/apis/goods'

const api = new GoodsClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const title = ref('')
    const remark = ref('')
    const goods = ref({})
    const count = ref(1)

    const userList = ref([])
    const user = ref()
    const userId = ref()

    const onClose = () => {
      count.value = 1
      loading.value = false
      visible.value = false
    }

    const onChooseUser = (e) => {
      console.log(e)
    }

    return {
      title,
      visible,
      loading,
      goods,
      count,
      remark,
      userList,
      user,
      userId,
      onClose,
      onChooseUser
    }
  },

  methods: {
    open (goods) {
      this.goods = goods
      this.title = goods.title + '直接出库'

      this.getPlatformUsers()
      this.visible = true
    },

    async getPlatformUsers () {
      const list = this.$store.state.data.ALL_USER.length > 0 ? this.$store.state.data.ALL_USER : await this.$store.dispatch('GetAllUsers')

      const vlist = []
      list.map(e => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.userList = vlist
    },

    onSubmitFun () {
      this.loading = true
      api
        .out({
          goodsId: this.goods.id,
          userId: this.userId,
          quantity: this.count,
          remark: this.remark
        })
        .then(() => {
          this.$message.success('直接出库给' + this.userList.filter(x => x.value === this.userId)[0].label + '成功')
          this.onClose()
        })
    }
  }
})
</script>

<style lang="less" scoped></style>

<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info
          :labelCol="7"
          :valueCol="16"
          title="剩余库存"
          :value="goods.quantity"
        />
        <e-info :labelCol="7" :valueCol="16" title="单位" :value="goods.unit" />
        <e-input-number
          :labelCol="7"
          :valueCol="16"
          title="采购数量"
          v-model:value="count"
          :min="0"
        />
        <e-select
          :labelCol="7"
          :valueCol="16"
          title="采购计划"
          v-model:value="planId"
          :options="plans"
        />
        <e-select
          title="采购用户"
          :labelCol="7"
          :valueCol="16"
          v-model:value="userId"
          :options="userList"
        />

        <e-textarea
          :labelCol="7"
          :valueCol="16"
          title="备注"
          v-model:value="remark"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { PlanItemClass } from '@/apis/plan'
import dayjs from 'dayjs'

const api = new PlanItemClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const title = ref('')
    const goods = ref({})
    const unit = ref('')
    const remark = ref('')
    const planId = ref('')
    const userId = ref('')
    const count = ref(1)
    const plans = ref([])
    const userList = ref([])

    const onClose = () => {
      count.value = 1
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      goods,
      count,
      unit,
      onClose,
      remark,
      plans,
      planId,
      userId,
      userList
    }
  },

  methods: {
    async getPlatformUsers () {
      const list =
        this.$store.state.data.ALL_USER.length > 0
          ? this.$store.state.data.ALL_USER
          : await this.$store.dispatch('GetAllUsers')

      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.userList = vlist
    },
    open (formData) {
      this.goods = formData
      this.title = '添加“' + formData.title + '”采购'
      this.getAllPlan()
      this.getPlatformUsers()
      this.visible = true
    },

    async getAllPlan () {
      const list =
        this.$store.state.data.ALL_PLAN.length > 0
          ? this.$store.state.data.ALL_PLAN
          : await this.$store.dispatch('GetAllPlans')
      const vlist = []
      list.map((e) => {
        vlist.push({
          label: dayjs(e.planDate).format('YYYY-MM-DD'),
          value: e.id
        })
      })
      this.plans = vlist
    },

    onSubmitFun () {
      this.loading = true
      api
        .createOne({
          goodsId: this.goods.id,
          count: this.count,
          remark: this.remark,
          userId: this.userId,
          planId: this.planId
        })
        .then(() => {
          this.$message.success('添加采购清单成功')
          this.onClose()
        })
    }
  }
})
</script>

<style lang="less" scoped></style>

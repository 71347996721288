<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <s-input
                title="商品标题"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.Title"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-tree-select
                :labelCol="8"
                :valueCol="14"
                title="商品分类"
                :fieldNames="replaceFields"
                v-model:value="queryParams.GoodsCategoryId"
                :options="treeData"
              />
            </a-col>
            <a-col :sm="4" :xxl="3">
              <s-input-number
                title="库存阈值"
                :labelCol="10"
                :valueCol="10"
                v-model:value="queryParams.LessThanQuantity"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="$refs.eForm.open(null)"
              >
                添加商品
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'quantity'">
              <a-tag color="blue">{{ record.quantity }}</a-tag>
            </template>

            <template v-if="column.key === 'updateTime'">
              {{ $filters.formatDate(record.updateTime) }}
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>商品入库</template>
                <a @click.stop="$refs.eIn.open(record)">
                  <LoginOutlined />
                </a>
              </a-tooltip>

              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>直接出库</template>
                <a @click.stop="$refs.eOut.open(record)">
                  <LogoutOutlined />
                </a>
              </a-tooltip>

              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>添加采购</template>
                <a @click.stop="$refs.eBuy.open(record)">
                  <CarryOutOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="$refs.eForm.open(record)">
                  <EditOutlined />
                </a>
              </a-tooltip>

            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EForm ref="eForm" @ok="getDataList(1)" />
    <EBuy ref="eBuy" />
    <EIn ref="eIn" @ok="getDataList(1)" />
    <EOut ref="eOut" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  ExclamationCircleOutlined,
  LoginOutlined,
  CarryOutOutlined,
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons-vue'
import { GoodsClass } from '@/apis/goods'
import page from '@/mixins/page'
import { dataToTree } from '@/utils'
import EForm from '@/components/forms/goods/Form'
import EBuy from '@/components/forms/goods/BuyWithUser'
import EIn from '@/components/forms/goods/In'
import EOut from '@/components/forms/goods/Outdirect'

const api = new GoodsClass()
export default defineComponent({
  mixins: [page],
  components: {
    EForm,
    EBuy,
    EIn,
    LoginOutlined,
    CarryOutOutlined,
    EditOutlined,
    EOut,
    LogoutOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)
    const visiblePassword = ref(false)

    const id = ref('')
    const treeData = ref([])
    const units = ref([])

    const replaceFields = {
      value: 'id',
      key: 'id'
    }

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '商品标题',
        key: 'title',
        dataIndex: 'title'
      },
      {
        title: '商品分类',
        key: 'goodsCategoryName',
        dataIndex: 'goodsCategoryName',
        width: 100
      },
      {
        title: '规格',
        key: 'specification',
        dataIndex: 'specification',
        width: 200
      },
      {
        title: '品牌',
        key: 'brand',
        dataIndex: 'brand',
        width: 150
      },
      {
        title: '单位',
        key: 'unit',
        dataIndex: 'unit',
        width: 100
      },
      {
        title: '单价',
        key: 'stockPrice',
        dataIndex: 'stockPrice',
        width: 100
      },
      {
        title: '库存',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 80
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 150
      }
    ]
    return {
      columns,
      loading,
      height,
      width,
      visiblePassword,
      treeData,
      replaceFields,
      id,
      units
    }
  },
  created () {
    this.units = this.$store.state.app.units
    this.getDataList(1)
    this.getCategorys()
  },
  methods: {
    changeQuantity (e) {
      if (this.id) {
        api.quantity(this.id, e).then(() => {
          this.$message.success('修改库存成功')
          this.getDataList(this.page.page)
          this.$refs.eChangeNum.handleClose()
        })
      }
    },
    async getCategorys () {
      const resp =
        this.$store.state.data.ALL_GOODSCATEGORYS.length > 0
          ? this.$store.state.data.ALL_GOODSCATEGORYS
          : await this.$store.dispatch('GetAllGoodsCategorys')
      const data = dataToTree(resp, 0)
      this.treeData = data
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.title}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    changeStatus (goodsId, type, e) {
      console.log(goodsId, e, type)
      api[type](goodsId, e).then(() => {
        this.$message.success('操作成功')
        this.getDataList(this.page.page)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
.goods-info {
  display: flex;
  .goods-image {
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goods-title {
    padding-left: 10px;
    h1 {
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      line-height: 30px;
    }
    p {
      font-size: 12px;
      color: #9b9b9b;
      line-height: 20px;
      height: 40px;
      padding: 0;
      margin: 0;
    }
    .goods-tags {
      font-size: 12px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
